import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { IProductFeatureAnimationProps, IProductFeatureAnimationState } from './productFeatureAnimation.d';
import './productFeatureAnimation.scss';
import Icon_Pause_3D from '../../Assets/svg/icon_pause_3d';
import Icon_Play_3D from '../../Assets/svg/icon_play_3d';
import { IResponseData } from '../../types/VariantSelection';
import { ThreeSixtyViewDataController } from '../common/threeSixtyViewDataController';
import ThreeSixtyAnimation from '../threeSixtyAnimation/threeSixtyAnimation';

export default class ProductFeatureAnimation extends React.Component<IProductFeatureAnimationProps,
    IProductFeatureAnimationState> {

    private threeSixtyViewController: ThreeSixtyViewDataController;
    private threeSixtyAnimationRef = React.createRef<ThreeSixtyAnimation>();

    constructor(props) {
        super(props);

        this.state = {
            playAnimation: false,
            sliderIndex: 1,
            currentThreeSixty: this.props.threeSixty,
            colorCode: this.props.colorCode,
            hideContent: this.props.threeSixty && this.props.threeSixty.imageUrlArray
                && this.props.threeSixty.imageUrlArray.length > 0 ? false : true,
            productInfo: this.props.productInfo
        };

        this.threeSixtyViewController = ThreeSixtyViewDataController.instance;

        this.handle3DAnimationStartStopClick = this.handle3DAnimationStartStopClick.bind(this);
        this.handleSliderOnChange = this.handleSliderOnChange.bind(this);
        this.updateSliderIndex = this.updateSliderIndex.bind(this);
        this.handleESPPDetailPageUpdate = this.handleESPPDetailPageUpdate.bind(this);
    }

    componentDidMount() {
        if (window.shell) {
            window.shell.subscribeTo('ESPP.DetailPage.PageUpdate',
                (response: IResponseData) => {
                    if (this.state.colorCode !== response.variantSelection.selectedColor.code) {
                        const oldPI = this.state.productInfo;

                        this.setState({
                            colorCode: response.variantSelection.selectedColor.code,
                            productInfo: oldPI
                        }, () => {
                            this.handleESPPDetailPageUpdate(
                                response.variantSelection.selectedColor.code);
                        });
                    }
                },
                this.props.containerId);
        }

        if (!this.state.hideContent) {
            setTimeout(() => {
                if (this.threeSixtyAnimationRef.current)
                    this.threeSixtyAnimationRef.current.initAnimation();
            }, 500);
        }
    }

    private handleESPPDetailPageUpdate(colorCode: number): void {
        this.threeSixtyViewController.getThreeSixtyViewData(colorCode, this.props.masterArticleNo,
            this.props.generalData.originalRootPath, '$$$' + this.props.showCase + '$$$')
            .then((threeSixtyView) => {
                if (threeSixtyView && threeSixtyView.threeSixty
                    && threeSixtyView.threeSixty.imageUrlArray
                    && threeSixtyView.threeSixty.imageUrlArray.length > 0) {
                    this.setState({
                        currentThreeSixty: threeSixtyView.threeSixty,
                        playAnimation: false
                    }, () => {
                        this.restoreContent();
                    });
                }
                else {
                    this.hideContent();
                }
            });
    }

    private handle3DAnimationStartStopClick(): void {
        this.setState({ playAnimation: !this.state.playAnimation },
            () => {
                this.threeSixtyAnimationRef.current.playStopAnimation(this.state.playAnimation);
            });
    }

    private handleSliderOnChange(sliderIndex: number): void {
        if (this.state.playAnimation) {
            this.setState({ playAnimation: false },
                () => {
                    this.threeSixtyAnimationRef.current.playStopAnimation(false);
                });
        }

        this.threeSixtyAnimationRef.current.setState({
            imageIndex: this.getImageIndex(sliderIndex),
            animationSequenceIndex: sliderIndex
        });

        this.setState({
            sliderIndex: sliderIndex
        });
    }

    private getImageIndex(sliderIndex: number): number {
        return this.state.currentThreeSixty.animationSequence[sliderIndex-1].imageIndex
    }

    private updateSliderIndex(imageIndex: number): void {
        this.setState({
            sliderIndex: imageIndex
        });
    }

    public hideContent(): void {
        this.setState({hideContent: true});
    }

    public restoreContent(): void {
        this.setState({hideContent: false}, () => {
            this.threeSixtyAnimationRef.current.initAnimation();
            this.threeSixtyAnimationRef.current.restartAnimation(false);
            this.updateSliderIndex(1);
        });
    }

    private getSliderStyles(): string {
        return this.state.playAnimation === true ? 'slider_container slider_transition_on': 'slider_container';
    }

    public render() {
        return (
            !this.state.hideContent ? 
            <div className={'product_feature_animation'}>
                {
                    <ThreeSixtyAnimation
                        ref={this.threeSixtyAnimationRef}
                        imageUrlArray={this.state.currentThreeSixty.imageUrlArray}
                        animationSpeed={this.state.currentThreeSixty.animationSpeed}
                        animationSequence={this.state.currentThreeSixty.animationSequence}
                        showOverlayAnimation={false}
                        containerId={this.props.containerId}
                        hasThreeSixtyThumbnails={false}
                        loadContentAfterPageLoad={false}
                        loadContentAfterPageLoadMobile={false}
                        generalData={this.props.generalData}
                        updateSliderIndex={this.updateSliderIndex}
                        preventAutoPlay={true}
                        productInfo={this.state.productInfo}
                    />
                }
                {this.state.currentThreeSixty.imageUrlArray.length > 1 &&
                    <div className={'animations_controllers'}>
                        <div className={'play_pause_btn'}
                            onClick={this.handle3DAnimationStartStopClick}>
                            {this.state.playAnimation ? <Icon_Pause_3D /> : <Icon_Play_3D />}
                        </div>
                        <Slider className={this.getSliderStyles()}
                            vertical={true}
                            min={1} 
                            max={this.state.currentThreeSixty.animationSequence.length}
                            value={this.state.sliderIndex}
                            reverse={true}
                            included={false}
                            onChange={this.handleSliderOnChange}
                        />
                    </div>
                }
            </div>
            :
            <div className={'scs_no_pf_animation'}></div>
        );
    }
}
